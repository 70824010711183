<template>
	<div>
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" append-to-body :close-on-click-modal="false" :visible.sync="dialogAddVisible"
			width="1000px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_24c8f46012a25c89')" append-to-body :visible.sync="dialogAddVisible" :direction="'rtl'" size="1000px">
			<!-- <div style=""> -->
			<!-- <div> -->
			<el-card style="" v-loading="loading">
				<div slot="header">
					<h3>{{$t('i18nn_f80dedf7c601059f')}}</h3>
				</div>
				<el-form :model="form" label-width="100px" v-loading="loading_load">
					<!-- <div> -->
					<el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="" required>
						<el-input type="text" v-model="form.title"></el-input>
					</el-form-item>

					<div style="margin: 20px 0;">
						<el-button @click="addLe2" type="primary" size="small" icon="el-icon-plus">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						
						<!-- <div style="margin-bottom: 10px;">
							<el-button type="primary" size="small" icon="el-icon-plus"
								@click="addRow">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
							<el-button type="danger" size="small" icon="el-icon-delete"
								@click="clearRow">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
						</div> -->

						<el-table :data="form.addServiceList" stripe :border="true" style="width: 100%" size="small">
							<el-table-column type="index" width="50" align="center" :label="$t('7b1c2b1adc920d9c')">
							</el-table-column>
							<el-table-column prop="serviceType" :label="$t('i18nn_f1d6776242105f03')">
								<template slot-scope="scope">
									<el-select filterable clearable size="small" v-model="scope.row.serviceType"
										:placeholder="$t('2ad108ab2c560530')" style="width: 200px;">
										<el-option v-for="(item,index) in selectOption.wh_op_add_service" :key="index"
											:label="$Utils.i18nCodeText(item)" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="goodsSku" :label="'SKU'">
								<template slot-scope="scope">
									<div>
										<el-button type="primary" size="small" icon="el-icon-magic-stick"
											@click="addRow($event,scope.$index)">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
											<span>{{scope.row.goodsSku}}</span>
									</div>
								</template>
							</el-table-column> -->
							
							<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')">
								<template slot-scope="scope">
									<el-input-number v-model="scope.row.quantity" size="small" controls-position="right"
										style="width: 200px;"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column :label="$t('93f5ca01b006206c')" width="200px">
								<template slot-scope="scope">
									<el-button @click="delLe2($event, scope.$index)" type="warning" size="small"
										icon="el-icon-minus">{{ $t('e33c9b93c36fd250') }}</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>

					<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="" required>
						<el-input type="textarea" v-model="form.remark"></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
						<div>
							<el-button type="primary" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
							<ul>
								<li v-for="(item,index) in form.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
										:fit="'contain'" :src="item.url" :preview-src-list="[item.url]">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

									<el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
									style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								</li>
							</ul>
						</div>
					</el-form-item>
				</el-form>
			</el-card>


			<!--  -->

			<!-- <el-button type="primary" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button> -->
			<!-- </div> -->
			<!-- </div> -->
			<div class="drawer-footer">
				<el-button type="primary" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
				<!-- <el-button type="primary" plain @click="dialogAddVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button> -->
			</div>
		</el-drawer>
		<!-- </el-dialog> -->
		
		<!-- 选择SKU -->
		<!-- <dialogWSku :openTime="skuInvOpenTime" :whNo="form.whNo" @sure="sureSel"></dialogWSku> -->

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'storage/addValService/'" :fileKey="'addValServiceFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>

	</div>
</template>
<script>
	// import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';
	
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';

	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		props: {

			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
			// relationId: {
			//   default: function() {
			//     return '';
			//   },
			//   type: String
			// }
		},
		components: {
			whNoSelect,
			dialogFileUpload,
			// dialogWSku
		},
		data() {
			return {
				loading: false,
				loading_load: false,
				dialogAddVisible: false,

				FileUploadOpenTime: '',
				
				skuInvOpenTime: '',
				skuOpenIndex:'',
				
				form: {
					"remark": "",
					title:"",
					whNo: "",
					attachments: [],
					"addServiceList": [
						// {
						// 	"serviceType": null, //"增值类型",
						// 	"goodsSku":"",
						// 	"quantity": null, //"数量"
						// },
					]
				},

				selectOption: {
					wh_op_add_service: [],
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogAddVisible = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.initData();
			this.getDicData();
		},
		methods: {
			initData() {
				this.dialogAddVisible = true;

				this.form = {
					"remark": "",
					title:"",
					whNo: "",
					attachments: [],
					"addServiceList": [
						// {
						// 	"serviceType": null, //"增值类型",
						// 	"goodsSku":"",
						// 	"quantity": null, //"数量"
						// },
					]
				};
				this.$nextTick(() => {
					this.$refs.whNoSelect.init("");
				});
				
				if (this.id) {
					// this.tableData = [];
					// this.DetData = {};
					this.getDetPageData(this.id);
					this.getAddServiceDetPageData(this.id);
				}

			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},

			//新增
			addLe2() {
				this.form.addServiceList.push({
					"goodsSku":"",
					"serviceType": null, //"增值类型",
					"quantity": null, //"数量"
				});
			},
			
			// //删除
			delLe2(event, index) {
				event.stopPropagation();
				this.form.addServiceList.splice(index, 1);
			},
			
			// 选择-sku
			addRow(event,index) {
				event.stopPropagation();
				if(!this.form.whNo){
					this.$message.warning(this.$t('FormMsg.Select_long_wh'))
					return;
				}
				// this.skuInvWhNo = this.form.senderAddr;
				this.skuInvOpenTime = new Date().getTime();
				this.skuOpenIndex = index;
			},
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.addServiceList.push({
			// 				goodsSku: item.goodsSku,
			// 				"serviceType": null, //"增值类型",
			// 				"quantity": null, //"数量"
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val){
				val.forEach((item,index)=>{
					// let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
						if(0==index){//第一条直接录入
							// this.form.addServiceList[this.skuOpenIndex].goodsSku = item.goodsSku;
							let oneAdd = this.form.addServiceList[this.skuOpenIndex];
							oneAdd.goodsSku = item.goodsSku;
							this.$set(this.form.addServiceList,this.skuOpenIndex,oneAdd)
						} else {//其他插入新数据
							this.form.addServiceList.push({
								goodsSku: item.goodsSku,
								"serviceType": null, //"增值类型",
								"quantity": null, //"数量"
							});
						}
					// }
				})
			},
			
			// clearRow(){
			// 	this.form.addServiceList = [];
			// },
			
			

			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //"文件名称",
						url: item.filePath, //"地址"
						// type: "90"
					}
				});
				// this.form.attachments = fileList;
				this.form.attachments = this.form.attachments.concat(fileList);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			// fomatterQaTypeToBizType(qaType){
			// 	// bizType
			// 	//code: "SDS", codeText: "一件代发",
			// 	//code: "SRE", codeText: "退货换标", 
			// 	//code: "STF", codeText: "自提/快递", 
			// 	//code: "SCC", codeText: "入库预报", 
			// 	//code: "STR", codeText: "中转/转运"

			// 	// qaBizType
			// 	// code: "10", codeText: "一件代发",
			// 	// code: "20", codeText: "自提",
			// 	// code: "30", codeText: "转运", 
			// 	// code: "40", codeText: "收货入库", 
			// 	// code: "99", codeText: "其他",
			// 	let bizType = '';
			// 	if('10'==qaType){
			// 		bizType = 'SDS';
			// 	} else if('20'==qaType){
			// 		bizType = 'STF';
			// 	} else if('30'==qaType){//已废弃
			// 		bizType = 'STR';
			// 	} else if('40'==qaType){
			// 		bizType = 'SCC';
			// 	} else if('50'==qaType){
			// 		bizType = 'SRE';
			// 	}
			// 	return bizType;
			// },
			
			//请求分页数据
			getDetPageData(id) {
				// let _this = this;
				this.loading_load = true;
				this.$http
					// .get(this.$urlConfig.WhWorkOrderDet + '/' + id, {})
					.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						// this.$vux.loading.hide();
						this.loading_load = false;
						//表格显示数据
						// this.form = data.data;
						// this.form = {
						// 	"remark": "",
						// 	title:"",
						// 	whNo: "",
						// 	attachments: [],
						// 	"addServiceList": [
						// 		// {
						// 		// 	"serviceType": null, //"增值类型",
						// 		// 	"goodsSku":"",
						// 		// 	"quantity": null, //"数量"
						// 		// },
						// 	]
						// };
						if(200==data.code&&data.data) {
							this.form.id = data.data.id;
							this.form.remark = data.data.remark;
							this.form.title = data.data.title;
							
							this.form.whNo = data.data.whNo;
							this.form.attachments = data.data.attachments;
						}
						// this.$nextTick(() => {
						// 	this.$refs.whNoSelect.init("");
						// });
						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						})
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求分页数据
			getAddServiceDetPageData(id) {
				// this.tableData = [];
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhWorkAddServiceDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						// this.$vux.loading.hide();
						this.loading = false;
						if(200==data.code && data.rows){
							this.form.addServiceList = data.rows;
						}
						
						//表格显示数据
						// this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			
			//提交信息
			submitForm() {
				// let bizType = this.fomatterQaTypeToBizType(this.qaType);
				let formData = Object.assign({}, this.form);
				// formData.relationId = this.relationId;
				// formData.bizType = bizType;
				this.postData(this.$urlConfig.WhValAddServiceOtherAdd, formData, '', () => {
					this.$emit('success');
					this.dialogAddVisible = false;
					
					// this.getPageData();
					// this.$message.success(this.$t('9f30371831a98237'));
				});
			},
			//提交数据
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;

				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType
					.then(({
						data
					}) => {
						console.log('提交成功');
						console.log(data);
						// this.$vux.loading.hide();
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//查询数据字典
			getDicData() {
				// let _this = this;
				// console.log(keyword);

				// this.loading_load = true;
				this.$http
					.put(this.$urlConfig.HyDicQueryList, ['wh_op_add_service'])
					.then(({
						data
					}) => {
						console.log('查询数据字典，请求成功');
						console.log(data);
						if (200 == data.code && data.data) {
							this.selectOption.wh_op_add_service = data.data['wh_op_add_service'];
						} else {
							// if (!data.msg) {
							//   data.msg = this.$t("tips.submitError");
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log('查询数据字典接口，请求失败');
						// this.$message.error(this.$t("tips.submitRequestError"));
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
