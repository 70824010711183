<template>
	<div class="" v-loading="loading_load">
		<el-descriptions :title="$t('i18nn_3644a90f9f13f0b8')">
			<el-descriptions-item :label="$t('i18nn_6235565b185f0725')">{{form.workNo}}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_c944a6686d996ab3')">{{form.whNo}}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_7431e76029678ec7')">{{form.title}}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_f566f50ef680920c')">{{ form.orderTypeName }}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_14b5f4f572736efe')">{{form.createTime}}</el-descriptions-item>
			<!-- <el-descriptions-item :label="$t('i18nn_15b3627faddccb1d')">
				<div class="pre-text">
					{{ form.remark }}
				</div>
			</el-descriptions-item> -->
		</el-descriptions>
		
		
		
		<el-descriptions title="">
			<el-descriptions-item :label="$t('i18nn_15b3627faddccb1d')">
				<div class="pre-text">
					{{ form.remark }}
				</div>
			</el-descriptions-item>
		</el-descriptions>
		
		<el-descriptions title="">
			<el-descriptions-item :label="$t('i18nn_f5d43732e3f6cf4d')">
				<ul>
					<li v-for="(item,index) in form.attachments" :key="index">
						<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
							:fit="'contain'" :src="item.url" :preview-src-list="[item.url]">
							<div slot="error" class="image-slot">
								<i class="el-icon-document"></i>
							</div>
						</el-image>
						<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
		
						<!-- <el-button @click="delAction($event, item)" type="text" size="mini" icon="el-icon-delete"
						style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
					</li>
				</ul>
			</el-descriptions-item>
		</el-descriptions>

		<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" v-loading='loading'>
			<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')">
			</el-table-column>


			<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column>
			
			<el-table-column prop="sysOrderNo" :label="$t('i18nn_52a8c38184d84581')"></el-table-column>
			
			<el-table-column prop="bizTypeName" :label="$t('i18nn_32b164c53fa35e6d')"></el-table-column>
			
			<el-table-column prop="serviceTypeName" :label="$t('i18nn_61fc9e07fff97930')"></el-table-column>
			<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
			<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')"></el-table-column>
			
			<el-table-column prop="remark" :label="$t('15b3627faddccb1d')"></el-table-column>
			
			<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
				<template slot-scope="scope">
					<div>
						<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
						<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
					</div>
				</template>
			</el-table-column>

		</el-table>
	</div>
</template>
<script>
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	export default {

		props: {
			openTime:{},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		components: {
			// HyQuillEditor
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,

				form: {},

				loading_load: false,
				tableData: [],

				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30],
				// 	page_size: 10,
				// 	total: 0
				// },
				// selectOption: {
				//   wh_weight_unit: [],
				//   wh_vol_unit: [],
				//   wh_goods_fee_type:[],
				//   statusList: [
				//     {
				//       value: '',
				//       label: '全部'
				//     },
				//     {
				//       value: '1',
				//       label: '未收货'
				//     },
				//     {
				//       value: '2',
				//       label: '收货中'
				//     },
				//     {
				//       value: '3',
				//       label: '收货完成'
				//     }
				//   ]
				// },
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc' //desc降序，asc升序
				// 	// "accountPeriod":"",
				// 	// packId: "",
				// 	// "billNo":""
				// 	// status: '',
				// 	// putWhNo: '',
				// 	// goodsSku: '',
				// 	// hashCode: '',
				// 	// goodsName: '',
				// 	// declareNameCh: '',
				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('watchKey');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				this.initData();
				// }
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				this.form = {};
				if (this.id) {
					// this.tableData = [];
					// this.DetData = {};
					this.getDetPageData(this.id);
					this.getAddServiceDetPageData(this.id);
				}
				// this.getDicData();
			},
			// 富文本
			onEditorChange(html) {
				// this.form.contentStr = html;
			},
			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },

			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			//请求分页数据
			getDetPageData(id) {
				// let _this = this;
				this.loading_load = true;
				this.$http
					// .get(this.$urlConfig.WhWorkOrderDet + '/' + id, {})
					.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						// this.$vux.loading.hide();
						this.loading_load = false;
						//表格显示数据
						this.form = data.data;
						
						// this.$nextTick(() => {
						// 	this.$refs.whNoSelect.init(this.form.whNo);
						// })
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求分页数据
			getAddServiceDetPageData(id) {
				this.tableData = [];
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhWorkAddServiceDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						// this.$vux.loading.hide();
						this.loading = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
